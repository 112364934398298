<template>
  <div class="schedule-container">
    <van-sticky>
      <head-bar :title="$store.state.groupName || getNameByLocation()" @onClickLeft="onClickLeft" />
    </van-sticky>
    <van-skeleton class="mt-20" title :row="3" :loading="loading"/>
    <template-one v-if="schedule.length > 0 && !loading && templateNum === 1" :scheduleData="scheduleData" />
    <van-empty v-if="!loading && schedule.length === 0" description="暂无数据"/>
  </div>
</template>

<script>
import templateOne from '@/components/scheduleTemplates/templateOne'
import headBar from '@/components/headBar/headBar'
function arrTransformObj(arr) {
  let data = {}
  const originData = JSON.parse(JSON.stringify(arr))
  originData.forEach(element => {
    element.child = []
    if (element.parenterId === '') {
      data = element
    }
  })
  data.child = []
  originData.forEach(element => {
    if (data.nodeId === element.parenterId) {
      data.child.push(element)
    }
  })
  data.child && data.child.forEach(element => {
    element.child = []
    originData.forEach(item => {
      if (element.nodeId === item.parenterId) {
        element.child.push(item)
      }
    })
  })
  data.child && data.child.forEach(every => {
    every.child && every.child.forEach(element => {
      element.child = []
      originData.forEach(item => {
        if (element.nodeId === item.parenterId) {
          element.child.push(item)
        }
      })
    })
  })
  data.child && data.child.forEach(every => {
    every.child && every.child.forEach(element => {
      element.child && element.child.forEach(jtem => {
        jtem.child = []
        originData.forEach(item => {
          if (jtem.nodeId === item.parenterId) {
            jtem.child.push(item)
          }
        })
      })
    })
  })
  return data
}
export default {
  name: 'schedule',
  components: { templateOne, headBar },
  data() {
    return {
      schedule: [],
      scheduleData: {},
      loading: true,
      templateNum: 2
    }
  },
  mounted() {
    this.getSchedule()
  },
  methods: {
    async getSchedule() {
      this.loading = true
      let id = ''
      if (this.$route.params.id) {
        id = this.$route.params.id
      }
      const result = await this.$http.postParams(this.$baseURL.index.getSchedule(), {
        groupId: this.$store.state.groupId || id
      })
      this.loading = false
      this.schedule = result
      this.scheduleData = arrTransformObj(result)
    },
    onClickLeft() {
      this.$parent.onChange(0)
    },
    getNameByLocation() {
      let name = ''
      if (this.$route.params.name) {
        name = this.$route.params.name
      }
      return name
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-skeleton__row, .van-skeleton__title{
  background: #dedede;
}
.schedule-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .mould-container, .main-container {
    flex: 1;
  }
}
/deep/ .van-nav-bar__title,
/deep/ .van-nav-bar__text,
/deep/ .van-nav-bar__arrow{
  color: #fff;
}
</style>