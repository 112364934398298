<template>
  <div style="height:100%">
    <div class="mould-container"
      v-if="scheduleData.title"
      :style="{
        '--scheduleBgColor': scheduleBgColor,
        '--scheduleBgColorS': scheduleBgColorS,
        '--personWordColor': personWordColor,
        '--contactWordColor': contactWordColor
      }">
      <div class="mould-head">
        会议日程
      </div>
      <!-- 主持人 -->
      <div class="mould-main">
        <div class="schedule-title">{{ scheduleData.title }}
          <div class="mb-5" v-if="scheduleData.startDate || (scheduleData.startTime && scheduleData.endTime)">
            (<span v-if="scheduleData.startDate">{{ scheduleData.startDate.substr(5) + '~' + scheduleData.endDate.substr(5) }}</span>
            <span v-if="scheduleData.startTime && scheduleData.endTime">
              <span v-if="scheduleData.startDate">&nbsp;&nbsp;&nbsp;</span>
              {{ scheduleData.startTime }}-{{ scheduleData.endTime }}
            </span>)
          </div>
        </div>
        <div class="schedule-host" v-if="scheduleData.host.hostList.length > 0">
          <div class="host-tip">{{ scheduleData.host.hostPrefix }}:</div>
          <div class="host-list">
            <div class="host-item" v-for="(item, index) in scheduleData.host.hostList" :key="index">
              <div class="host-name">
                <span>{{ item.name }}</span>
                <i></i>
              </div>
              <div class="host-role-list">
                <div class="host-role-item" v-for="(roleItem, roleIndex) in item.postList" :key="roleIndex">
                  {{roleItem.post}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 日程 -->
        <div class="schedule-list">
          <div v-for="(scheduleItem, scheduleIndex) in scheduleData.schedule" :key="scheduleIndex">
            <div class="schedule-tip">
              {{ scheduleItem.content }}
              <div v-if="scheduleItem.startDate || (scheduleItem.startTime && scheduleItem.endTime)">
                (<span v-if="scheduleItem.startDate">{{ scheduleItem.startDate.substr(5) }}</span>
                <span v-if="scheduleItem.startTime && scheduleItem.endTime">
                  <span v-if="scheduleItem.startDate">&nbsp;&nbsp;&nbsp;</span>
                  {{ scheduleItem.startTime }}-{{ scheduleItem.endTime }}
                </span>)
              </div>
            </div>
            <div v-if="scheduleItem.guestList.length > 0" class="guest-list">
              <div class="guest-item" v-for="(guestItem, guestIndex) in scheduleItem.guestList" :key="guestIndex">
                <div class="guest-name">
                  <span>{{ guestItem.name }}</span>
                  <i></i>
                </div>
                <div v-if="guestItem.postList" class="guest-role-list">
                  <div class="guest-role-item" v-for="(postItem, postIndex) in guestItem.postList" :key="postIndex">
                    {{ postItem.post }}
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-10">
              <div v-for="(item, index) in scheduleItem.scheduleList" class="schedule-item" :key="index">
                <div v-if="item.startTime && item.endTime" class="schedule-time">{{ item.startTime }}-{{ item.endTime }}</div>
                <div v-if="item.content" class="schedule-content">
                  <div class="schedule-theme">{{ item.content }}</div>
                  <div class="guest-list">
                    <div class="guest-item" v-for="(guestItem, guestIndex) in item.guestList" :key="guestIndex">
                      <div class="guest-name">
                        <span>{{ guestItem.name }}</span>
                        <i></i>
                      </div>
                      <div class="guest-role-list">
                        <div class="guest-role-item" v-for="(postItem, postIndex) in guestItem.postList" :key="postIndex">
                          {{ postItem.post }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="empty-container" v-else>
      <empty description="暂无数据" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    scheduleData: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      contactWordColor: state => state.themeInfo.contactWordColor,
      scheduleBgColor: state => state.themeInfo.scheduleBgColor,
      scheduleBgColorS: state => state.themeInfo.scheduleBgColorS,
      personWordColor: state => state.themeInfo.personWordColor
    })
  }
}
</script>
<style lang='less' scoped>
@scheduleBgColor: var(--scheduleBgColor);
@scheduleBgColorS: var(--scheduleBgColorS);
@personWordColor: var(--personWordColor);
@contactWordColor: var(--contactWordColor);
.mould-container {
  background: @scheduleBgColor;
  font-size: 14px;
  color: #fff;
  height: 100%;
  overflow-y: scroll;
  .mould-head {
    width: 320px;
    height: 40px;
    line-height: 40px;
    margin: 20px auto;
    background: @scheduleBgColorS;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
  }
  .mould-main {
    width: 320px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 100px;
    color: @personWordColor;
    .schedule-title {
      font-size: 16px;
      font-weight: bold;
      position: relative;
      &::before {
        display: inline-block;
        content: '';
        width: 10PX;
        height: 10PX;
        margin-right: 10PX;
        border-radius: 50%;
        background: @personWordColor;
      }
    }
    .schedule-host {
      color: @contactWordColor;
      font-weight: bold;
      display: flex;
      margin: 10px auto;
      padding-left: 20px;
      .host-tip {
        margin-right: 6px;
      }
      .host-list {
        .host-item {
          display: flex;
          .host-name {
            margin-right: 6px;
            width: 44px;
            height: 18px;
            text-align: justify;
            overflow: hidden;
            i {
              display:inline-block;
              width:100%;
            }
          }
        }
      }
    }
    .schedule-list {
      .schedule-tip {
        color: var(--contactWordColor);
        font-weight: bold;
      }
      .schedule-item {
        display: flex;
        .schedule-time {
          width: 100px;
          line-height: 1;
          margin-top: -2PX;
        }
        .schedule-content {
          flex: 1;
          border-left: 1PX solid @contactWordColor;
          padding: 0 0 20px 10px;
          position: relative;
          &::before {
            position: absolute;
            top: 0;
            left: -5PX;
            content: '';
            width: 9PX;
            height: 9PX;
            border-radius: 50%;
            background: @contactWordColor;
          }
          .schedule-theme{
            margin-top: -2PX;
            line-height: 2;
            &:first-line{
              line-height: 1;
            }
          }
        }
        &:nth-last-child(1) {
          .schedule-content {
            border-color: transparent;
          }
        }
      }
      .guest-list {
        color: @contactWordColor;
        .guest-item {
          display: flex;
          // font-weight: bold;
          margin-top: 4PX;
          .guest-name {
            width: 44px;
            height: 18px;
            margin-right: 6px;
            text-align: justify;
            overflow: hidden;
            i {
              display:inline-block;
              width:100%;
            }
          }
        }
      }
    }
  }
}
</style>
